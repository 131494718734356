import * as React from "react"
import { Row, Col, Button, Carousel, Container } from "react-bootstrap"
import "./tourdetails.scss"
import DetailsLayout from "../components/details-layout"
import Seo from "../components/seo" 
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"


 
export const query = graphql`
query TourDetailsQuery($slug: String!)  {
    graphCmsTours(slug: { eq: $slug }) {
        id
        slug
        tourLocation
        tourStatus
        tourTitle
        tourVideo
        displayPrice
        policy{
            html
            raw
            text
          }
        tourPrice{
            html
            raw
            text
          }
        tourUtilities {
          html
          raw
          text
        }
        tourIncludes {
          html
          raw
          text
        }
        tourImage {
          id
          imageAltText
          
          galleryImage {
            gatsbyImageData(quality: 60)
            url
          }
        }
        tourGallery {
          id
          imageAltText
         
          galleryImage {
            gatsbyImageData(quality: 60)
            url
          }
        }
        tourDuration {
          html
          text
          raw
        }
        tourDescription {
          html
          raw
          text
        }
    }
}`
const SingleListing = ({data, index}) => {

    const single = data.graphCmsTours;
    
    return (

    <DetailsLayout>
    <Seo
        title={single.tourTitle}
        description={single.tourDescription.text?.substring(0,155) || " "}
        image={single.tourImage.galleryImage.url}
    />
    <Helmet>
        <meta property='og:image' content={single.tourImage.galleryImage.url} />
        <meta property='twitter:image' content={single.tourImage.galleryImage.url} />
    </Helmet>

        <div className="single-listing">

        <Row>  
        <Col sm="7" id="left">

            <div className="image-gallery">
                <Carousel fade>
                    {(single.tourGallery || []).map((galleryMap, id)  => (
                    <Carousel.Item>
                        <GatsbyImage image={galleryMap.galleryImage.gatsbyImageData}
                            alt={galleryMap.imageAltText}/>
                    </Carousel.Item>
                    ))}
                </Carousel>
             
            </div>
        
        </Col>
        <Col sm="5" id="right"> 

        <div className="information" id="right">
                <div className="title-bar">
                    <div className="text-field">
                      
                        <h1>{single.tourTitle}</h1>
                        <h4>{single.tourLocation}</h4>
                    </div>
                </div>
            <div className="main-area">
                 {single.tourPrice && (
                    <div className="description" id="box">
                        <h5>Price</h5>
                        <div dangerouslySetInnerHTML={{ __html: single.tourPrice.html }} />
                    </div>
                )}

                {single.tourDescription && (
                    <div className="description" id="box">
                        <h5>Description</h5>
                        <div dangerouslySetInnerHTML={{ __html: single.tourDescription.html }} />
                    </div>
                )}
           
                {single.tourDuration && (
                    <div className="features" id="box">
                        <h5>Tour Duration</h5>
                        <div dangerouslySetInnerHTML={{ __html: single.tourDuration.html }} />
                    </div>
                )}  
         
                {single.tourIncludes && (
                    <div className="room-types" id="box">
                        <h5>Tour Includes</h5>
                        <div dangerouslySetInnerHTML={{ __html: single.tourIncludes.html }} />
                    </div>
                )}
        
                {single.tourUtilities && (
                    <div className="transportation" id="box">
                        <h5>What to Bring</h5>
                        <div dangerouslySetInnerHTML={{ __html: single.tourUtilities.html }} />
                    </div>
                )}


                    <div className="transportation" id="box">
                        <h5>Book Now</h5>
<div class="elfsight-app-e7154720-ae61-407b-8673-d998a826544e"></div>
                    </div>
                    {single.policy && (
                    <div className="transportation" id="box">
                        <h5>Booking Tours & Cancellation Policy</h5>
                        <div dangerouslySetInnerHTML={{ __html: single.policy.html }} />
                    </div>
                )}
          
            </div>

        </div>

        </Col>
        </Row>

        </div>
        
    </DetailsLayout>
    );
};

export default SingleListing
